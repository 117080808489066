/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { Text, Box, Flex, Link, Card } from "rebass";

import MainContainer from "../../ui-components/container/container";

interface CardProps {
  data: {
    title: string;
    cards: [
      {
        slug: string;
        title: string;
        subtitle: string;
        image: {
          file: {
            url: string;
          };
        };
        number: boolean;
      }
    ];
  };
}

const Cards = ({ data: { title, cards } }: CardProps) => {
  return (
    <MainContainer my={4}>
      <Text as="h2" variant="subheading">{title}</Text>
      <Flex justifyContent="space-between" flexWrap="wrap">
        {cards.map(({ slug, title, subtitle }, i) => {
          return (
            <Link href={slug} key={title} variant="cardLink">
              <Card variant="cardCompact">
                <Flex flex={["0 1 100%"]}>
                  <Box variant="cardNumberBox">
                    <Text as="p" variant="cardNumberText">
                      {i + 1}
                    </Text>
                  </Box>
                  <Text as="h3" color="black" variant="cardBigTitle">
                    {title}
                  </Text>
                </Flex>
                <Text as="p" variant="cardText">
                  {subtitle}
                </Text>
              </Card>
            </Link>
          );
        })}
      </Flex>
    </MainContainer>
  );
};

export default Cards;
