/** @jsx jsx */
// import { jsx } from "@emotion/core";
import { jsx } from "theme-ui";
import { FC } from "react";
import { Flex, Box, Text, Link } from "rebass";
import { Image } from "../images/images";

import MainContainer from "../../ui-components/container/container";

// @ts-ignore
import Facebook from "../../assets/social-media/facebook.svg";
// @ts-ignore
import Insta from "../../assets/social-media/insta.svg";
// @ts-ignore
import Linkedin from "../../assets/social-media/linkedin.svg";
// @ts-ignore
import Email from "../../assets/social-media/email.svg";

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    subtitle?: {
      subtitle: string;
    };
    link: [
      {
        slug: string;
        text: string;
        color: string;
        isButton: boolean;
      }
    ];
    image: any;
    color: string;
    socialIcons: boolean;
    shadowText: string;
  };
  titleAs?: "h1" | "h2";
  subtitleAs?: "h1" | "h2";
  css?: any;
}

const Hero = ({
  data: { title, subtitle, link, socialIcons, color, shadowText, image },
  className,
  id,
  titleAs,
  subtitleAs
}: Props) => {
  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      <Box
        bg="primary"
        sx={{
          zIndex: -1,
          left: "-50px",
          position: "absolute",
          width: "110%",
          top: ["20px"],
          height: "10px"
        }}
      />
      <MainContainer
        justifyContent="space-between"
        id={id}
        sx={{ position: "relative" }}
      >
        <Box
          flex={["0 1 100%", "0 1 52%"]}
          maxWidth={["100%", "52%"]}
          sx={{ position: "relative" }}
          marginTop={5}
        >
          <Text as={titleAs || "h1"} variant="title">
            {title}
          </Text>
          {subtitle && subtitle.subtitle && (
            <Text as={subtitleAs || "h2"} variant="subheading" pt={5} pb={3}>
              {subtitle.subtitle}
            </Text>
          )}
          {link.map((button: any) => (
            <Link href={button.slug} variant="buttonPrimary" key={button.text}>
              {button.text}
            </Link>
          ))}
          {socialIcons && (
            <Box my={4}>
              <a href="mailto:hello@emiliemartinez.com">
                <Email className="social-icons" />
              </a>
              <a
                href="https://www.linkedin.com/in/emiliekmartinez/"
                target="__blank"
              >
                <Linkedin className="social-icons" />
              </a>
              <a
                href="https://www.facebook.com/emiliekmartinez"
                target="__blank"
              >
                <Facebook className="social-icons" />
              </a>
              <a
                href="https://www.instagram.com/em__marketing/"
                target="__blank"
              >
                <Insta className="social-icons" />
              </a>
            </Box>
          )}
        </Box>
        <Flex
          flex={["0 1 100%", "0 1 43%"]}
          maxWidth={["100%", "43%"]}
          sx={{ position: "relative" }}
        >
          <Image src={image} className="image--paragraph" />
        </Flex>
        {shadowText && (
          <Text
            as="h6"
            variant="shadowText"
            sx={{
              color: "#f2f2f2",
              fontSize: "130px",
              lineHeight: "1.3em",
              fontWeight: "bold",
              position: "absolute",
              top: "150px",
              zIndex: -1,
              margin: 0,
              left: "0%",

              "@media screen and (max-width: 950px)": {
                display: "none"
              }
            }}
          >
            {shadowText}
          </Text>
        )}
      </MainContainer>
      <Box
        bg="primary"
        sx={{
          zIndex: -1,
          position: "absolute",
          height: "10px",
          width: "110%",
          bottom: ["-30px", "20px"],
          left: "-100px"
        }}
      />
    </Box>
  );
};

export default Hero;
