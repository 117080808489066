import * as React from "react";
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import Layout from "../ui-components/layout";
import BlogPosts from "../components/blog-posts/blog-posts";
import Hero from "../components/hero/hero";
import Card from "../components/cards/cards";
import Paragraph from "../components/paragraph/paragraph";
import HoverCard from "../components/hover-card/hover-card";
import SEO from "../components/seo/seo";
import Prefooter from "../components/prefooter/prefooter";

interface IndexPageProps {
  data: {
    allContentfulHomepage: {
      edges: {
        node: {
          id: number;
          metaTitle: string;
          metaDescription: {
            metaDescription: string;
          };
          socialMediaImage: {
            file: {
              url: string;
            };
          };
          language: [];
          title: string;
          hero: {
            title: string;
            subtitle: {
              subtitle: string;
            };
            link: {
              slug: string;
              text: string;
              color: string;
              isButton: boolean;
            };
            image: {
              file: {
                url: string;
              };
            };
            color: string;
            socialIcons: boolean;
            shadowText: string;
          };
          services: {
            title: string;
            cards: {
              slug: string;
              title: string;
              subtitle: string;
              image: {
                file: {
                  url: string;
                };
              };
              number: boolean;
            };
          };
          about: {
            image: {
              file: {
                url: string;
              };
            };
            title: string;
            text: {
              text: string;
            };
            accentLines: string;
            smallImage: boolean;
          };
          recentProjects: {
            title: string;
            cards: {
              slug: string;
              title: string;
              subtitle: string;
              image: {
                file: {
                  url: string;
                };
              };
              number: boolean;
            };
          };
        };
      };
    };
  };
}

export const indexPageQuery = graphql`
  query IndexPageQuery {
    allContentfulHomepage(limit: 1) {
      edges {
        node {
          id
          metaTitle
          metaDescription {
            metaDescription
          }
          socialMediaImage {
            file {
              url
            }
          }
          language
          title
          hero {
            title
            subtitle {
              subtitle
            }
            link {
              slug
              text
              color
              isButton
            }
            image {
              ...ContentfulAsset
            }
            color
            socialIcons
            shadowText
          }
          services {
            title
            cards {
              slug
              title
              subtitle
              number
            }
          }
          about {
            image {
              ...ContentfulAsset
            }
            title
            text {
              text
            }
            accentLines
            smallImage
          }
          recentProjects {
            title
            cards {
              slug
              title
              subtitle
              image {
                ...ContentfulAsset
              }
              number
            }
          }
          prefooter {
            ...Prefooter
          }
        }
      }
    }
  }
`;

export default class IndexPage extends React.Component<IndexPageProps, {}> {
  public render() {
    // @ts-ignore
    const { node: {metaDescription, metaTitle, slug, socialMediaImage, language, hero, services, recentProjects, about, prefooter} } = this.props.data.allContentfulHomepage
      .edges[0] as IndexPageProps;
    return (
      <Layout>
        <SEO
          title={metaTitle}
          description={metaDescription.metaDescription}
          image={socialMediaImage.file.url}
          pathname={slug}
          lang={language}
        />
        <main>
          <Hero data={hero} />
          <Card data={services} />
          <HoverCard data={recentProjects} maxNumber={4} id="work" />
          <Paragraph data={about} id="about" />
          <BlogPosts title limitedPosts />
          <Prefooter data={prefooter} />
        </main>
      </Layout>
    );
  }
}
