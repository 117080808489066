import React from "react";
import { jsx } from "theme-ui";
import Markdown from "react-markdown";
import { Box, Text, Flex, Link } from "rebass";
import { Image } from "../images/images";

import MainContainer from "../../ui-components/container/container";

interface Props {
  data: {
    image: any;
    title: string;
    text: {
      text: string;
    };
    accentLines: string;
    smallImage: boolean;
  };
  id: string;
}

const Paragraph = ({ data, id }: Props) => {
  return (
    <MainContainer justifyContent="space-between" id={id}>
      <Flex flex={["0 1 100%", "0 1 48%"]} maxWidth={["100%", "48%"]} sx={{position: 'relative', overflow: 'hidden'}}>
        <Box
          bg="primary"
          sx={{
            zIndex: -1,
            left: "-50px",
            position: "absolute",
            width: "150%",
            top: ["20px"],
            height: "10px"
          }}
        />
        <Image src={data.image} className="image--paragraph" />
      </Flex>
      <Box flex={["0 1 100%", "0 1 48%"]} maxWidth={["100%", "48%"]} sx={{position: 'relative', overflow: 'hidden'}}>
        <Text as="h2" variant="subheading" pt={[2, 6]}>{data.title}</Text>
        <Markdown source={data.text.text} />
        <Link
          href="mailto:hello@emiliemartinez.com"
          mt={[2]}
          margin="auto"
          mr="0"
          variant="buttonPrimary"
        >
          Let's chat
        </Link>
        <Box
          bg="primary"
          sx={{
            zIndex: -1,
            position: "absolute",
            height: "10px",
            width: '135%',
            bottom: ['-30px', '20px'],
            left: '-100px'
          }}
        />
      </Box>
    </MainContainer>
  );
};

export default Paragraph;
